<template>
  <b-card>
    <div>
      <div class="text-black text-2xl font-semibold">
        Ubah Data Event
      </div>
    </div>
    <div class="lg:p-4">
      <b-row class="mt-3">
        <b-col md="6">
          <b-form-group
            id="title_event"
            label-cols="4"
            label-cols-lg="3"
            label="Judul Event*"
            label-class="font-semibold text-7 text-[#333333]"
            label-for="title_event"
          >
            <b-form-input
              id="title_event"
              v-model="state.form.event_title"
              type="text"
              :maxlength="30"
              :minlength="3"
              placeholder="Buat judul event"
              :class="error.form.event_title?'border-primary': ''"
              required
            />
            <p
              v-if="error.form.event_title"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_title }}
            </p>
          </b-form-group>
          <b-form-group
            id="t"
            label-cols="4"
            label-cols-lg="3"
            label="Poster*"
            label-class="font-semibold text-7 text-black"
            label-for="poster"
            class=""
          >
            <div style="display: flex; gap: 1rem;">
              <b-img
                :src="state.form.poster"
                fluid
                alt="poster-image"
                style="width: 120px; height: 74px; object-fit: cover;"
              />
              <b-input-group class="">
                <b-input-group-append
                  class="d-flex align-items-center"
                  :class="error.form.poster?'border-primary': ''"
                  @click="$refs.photoFile.click()"
                >

                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit.svg"
                    alt=""
                  >

                </b-input-group-append>
                <input
                  id="poster"
                  ref="photoFile"
                  class="form-control"
                  type="file"
                  style="display: none"
                  @change="validateExt"
                >

              </b-input-group>
            </div>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Jumlah Peserta*"
            label-class="font-semibold text-7 text-black"
            label-for="max_participants"
          >

            <b-form-input
              id="max_participants"
              v-model="state.form.max_attendance"
              type="number"
              placeholder="99"
              class="mb-2"
              :class="error.form.max_attendance?'border-primary': ''"
              required
              @input="maxNumberLength($event, 5)"
              @keypress="isNumber($event)"
            />
            <p
              v-if="error.form.max_attendance"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.max_attendance }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Diselenggarakan*"
            label-cols="4"
            label-cols-lg="3"
            label-class="font-semibold flex items-center text-7 text-black"
          >
            <b-form-radio-group
              v-model="state.form.event_status"
              :options="state.radio"
              style="accent-color: #F95031; margin-top: 0.6rem;"
              name="radio-inline"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Lokasi*"
            label-cols="4"
            label-cols-lg="3"
            class="mt-[1.5rem]"
            label-class="font-semibold text-7 text-black"
            label-for="location"
          >

            <b-form-input
              id="location"
              v-model="state.form.event_location"
              type="text"
              class="mb-2"
              required
              :class="error.form.event_location?'border-primary': ''"
            />
            <p
              v-if="error.form.event_location"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_location }}
            </p>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Sub-Judul Event*"
            label-class="font-semibold text-7 text-black"
            label-for="sub_title_event"
          >

            <b-form-textarea
              id="sub_title_event"
              v-model="state.form.event_sub_title"
              rows="3"
              placeholder="Buat sub-judul event"
              :class="error.form.event_sub_title?'border-primary': ''"
            />
            <p
              v-if="error.form.event_sub_title"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_sub_title }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Type Event*"
            label-class="font-semibold text-7 text-black"
            label-for="type_event"
          >
            <v-select
              id="type-event"
              v-model="state.form.event_type"
              :options="state.options"
              label="type_event"
              placeholder="Pilih type event"
              :class="error.form.event_type?'border-primary': ''"
            />
            <p
              v-if="error.form.event_type"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_type }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Harga*"
            label-cols="4"
            label-cols-lg="3"
            label-class="font-semibold text-7 text-black"
            label-for="price"
          >

            <b-input-group>
              <template #prepend>
                <b-input-group-text style="background-color: #F4F4F4; color:#626262">
                  Rp
                </b-input-group-text>
              </template>
              <b-form-input
                id="price"
                v-model="state.form.event_price"
                type="text"
                placeholder="0"
                required
                :class="error.form.event_price?'border-primary': ''"
                @input="formatPrice"
                @keypress="handleInput($event)"
              />
            </b-input-group>
            <p
              v-if="error.form.event_price"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_price }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Waktu*"
            label-cols="4"
            label-cols-lg="3"
            label-class="font-semibold text-7 text-black"
            label-for="date"
          >
            <date-range-picker
              ref="picker"
              v-model="state.dateRangeActivity"
              :locale-data="state.locale"
              :ranges="false"
              :show-ranges="false"
              class="w-[100%]"
              :class="error.form.start_date?'border-primary': ''"
            >
              <template
                v-slot:input="picker"
              >
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div
                    v-if="state.dateRangeActivity.startDate === null"
                  >
                    Pilih waktu event
                  </div>
                  <div v-else>
                    {{ DAY_MONTH_YEAR( picker.startDate ) }} - {{ DAY_MONTH_YEAR(picker.endDate ) }}
                  </div>
                  <div
                    class="ml-1"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                      alt="calendar"
                      srcset=""
                    >
                  </div>
                </div>

              </template>
            </date-range-picker>
            <p
              v-if="error.form.event_start_date"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_start_date }}
            </p>
          </b-form-group>
          <b-form-group
            id="link"
            label-cols="4"
            label-cols-lg="3"
            label="Link Landing Page"
            label-class="font-semibold text-7 text-black"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="state.form.link_landingpage"
              type="url"
              placeholder="Masukkan Link Event"
              required
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="auto"
        >
          <div class="font-semibold text-7 text-black">
            Deskripsi*
          </div>

        </b-col>
        <b-col>
          <div class="pl-3">
            <b-form-textarea
              id="textarea-rows"
              v-model="state.form.description"
              placeholder="Masukan Deskripsi Event"
              rows="8"
              :maxlength="250"
              :class="error.form.description?'border-primary': ''"
            />
            <span class="text-xs flex justify-content-end"> {{ state.form.description.length }}/250</span>
            <p
              v-if="error.form.description"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.description }}
            </p>
          </div>

        </b-col>
      </b-row>
    </div>
    <BRow>
      <BCol>
        <div
          class="d-flex justify-end "
          style="gap: 1rem;"
        >
          <b-button
            variant="outline-primary"
            @click="handleBack"
          >
            Kembali
          </b-button>
          <b-button
            v-b-modal.modal-add-event
            variant="primary"
            type="submit"
            :disabled="event.hasEditEvent ? event.submit : !event.hasEditEvent"
            @click="handleSubmit"
          >
            Simpan
          </b-button>
        </div>
      </BCol>
    </BRow>
  </b-card>

</template>

<script>
import {
  reactive, ref, onMounted, computed, watchEffect,
} from '@vue/composition-api'
import { newAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import router from '@/router'
import moment from 'moment'
import { isNumber } from '@/libs/helpers'
import { useRouter } from '@/@core/utils/utils'
import dateRangePicker from 'vue2-daterange-picker'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import { YEAR_MONTH_DAY, DAY_MONTH_YEAR } from '@/libs/filterDate'
import { toastSuccess, toastError } from '../config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    vSelect,
    dateRangePicker,
  },

  setup() {
    const photoUpload = ref({})
    const route = useRouter()
    const error = reactive({
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
    })
    const event = reactive({
      data: {},
      submit: true,
      hasEditEvent: false,
    })
    const picker = ref({
      startDate: firstDateOfMonth,
      endDate: lastDateOfMonth,
    })

    const state = reactive({
      photoName: '',
      disabledSubmit: true,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      dateRangeUpdate: {
        startDate: today,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: '',
        endDate: '',
      },
      data: {},
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
      radio: [
        { text: 'Online', value: 'online' },
        { text: 'Ofline', value: 'offline' },
      ],
      options:
      ['Bootcamp', 'Gathering', 'Kopdar Komunitas', 'Webinar'],
    })

    const formatPriceGet = inputValue => {
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      return formattedValue
    }

    async function getListData() {
      const idEvent = route.route.value.query.id
      const url = `/komclass/api/v1/event/${idEvent}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          const price = formatPriceGet(data.event_detail.event_price.toString())
          event.data = data.event_detail
          state.form.event_type = data.event_detail.event_type
          state.form.event_title = data.event_detail.event_title
          state.form.event_sub_title = data.event_detail.event_sub_title
          state.form.event_status = data.event_detail.event_status
          state.form.max_attendance = data.event_detail.max_attendance
          state.form.event_price = price
          state.form.event_location = data.event_detail.event_location
          state.dateRangeActivity.startDate = moment(data.event_detail.event_date_start)
          state.dateRangeActivity.endDate = moment(data.event_detail.event_date_end)
          state.form.description = data.event_detail.description
          state.form.poster = data.event_detail.poster
          state.form.link_landingpage = data.event_detail.link_landingpage
        })

        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }

    function handleConfirmSubmit() {
      const url = `/komclass/api/v1/event/${route.route.value.query.id}`
      newAxiosIns.put(url, state.data)
        .then(res => {
          const { data } = res.data
          router.push({
            name: 'data-event',
          })
          toastSuccess('Berhasil mengubah event')
        })
        .catch(err => {
          router.push({
            name: 'data-event',
          })
          toastError('Ada kesalahan saat mengubah event')
        })
    }

    function handleSubmit() {
      const price = state.form.event_price.split('.').join('')
      const formData = new FormData()
      formData.append('event_type', state.form.event_type)
      formData.append('event_title', state.form.event_title)
      formData.append('event_sub_title', state.form.event_sub_title)
      formData.append('event_status', state.form.event_status)
      formData.append('event_price', parseInt(price, 10))
      formData.append('event_location', state.form.event_location)
      formData.append('max_attendance', parseInt(state.form.max_attendance, 10))
      formData.append('event_start_date', YEAR_MONTH_DAY(state.dateRangeActivity.startDate))
      formData.append('event_end_date', YEAR_MONTH_DAY(state.dateRangeActivity.endDate))
      formData.append('poster', state.form.poster === event.data.poster ? '' : photoUpload.value)
      formData.append('description', state.form.description)
      formData.append('link_landingpage', state.form.link_landingpage)

      state.data = formData
      handleConfirmSubmit()
    }

    function handleBack() {
      router.back()
    }

    const checkDataChanged = () => {
      event.hasEditEvent = state.form.event_type !== event.data.event_type
      || state.form.event_title !== event.data.event_title
      || state.form.event_sub_title !== event.data.event_sub_title
      || state.form.event_status !== event.data.event_status
      || parseInt(state.form.max_attendance, 10) !== parseInt(event.data.max_attendance, 10)
      || parseInt(state.form.event_price.split('.').join(''), 10) !== parseInt(event.data.event_price, 10)
      || state.form.event_location !== event.data.event_location
      || DAY_MONTH_YEAR(state.dateRangeActivity.startDate) !== DAY_MONTH_YEAR(event.data.event_date_start)
      || DAY_MONTH_YEAR(state.dateRangeActivity.endDate) !== DAY_MONTH_YEAR(event.data.event_date_end)
      || state.form.description !== event.data.description
      || state.form.link_landingpage !== event.data.link_landingpage
      || state.form.poster !== event.data.poster
    }

    const isFormValid = () => {
      event.submit = !state.form.event_type
              || state.form.event_title.length >= 30
              || state.form.event_title.length <= 3
              || state.form.event_sub_title.length >= 256
              || state.form.event_sub_title === ''
              || state.form.poster === ''
              || state.form.max_attendance === ''
              || state.form.event_price === ''
              || state.form.event_location.length >= 256
              || state.form.event_location === ''
              || state.form.event_status === ''
              || state.dateRangeActivity.startDate === null
              || state.dateRangeActivity.endDate === null
              || state.form.event_sub_title === ''
              || state.form.description === ''
    }

    const isEmpty = () => {
      switch (true) {
        case !state.form.event_type:
          error.form.event_type = 'Masukkan Type Event'
          break
        case state.form.event_title === '':
          error.form.event_title = 'Masukkan Judul Event'
          break
        case state.form.event_sub_title === '':
          error.form.event_sub_title = 'Masukkan Sub-Judul Event'
          break
        case state.form.event_price === '':
          error.form.event_price = 'Masukkan Harga Event'
          break
        case state.form.event_location === '':
          error.form.event_location = 'Masukkan Lokasi Event'
          break
        case state.form.max_attendance === '':
          error.form.max_attendance = 'Masukkan Jumlah Peserta'
          break
        case state.form.description === '':
          error.form.description = 'Masukkan Deskripsi Event'
          break
        case state.dateRangeActivity.startDate === null:
          error.form.event_start_date = 'Masukkan Waktu Event'
          break
        default:
          error.form.event_title = ''
          error.form.event_type = ''
          error.form.event_sub_title = ''
          error.form.event_price = ''
          error.form.event_location = ''
          error.form.description = ''
          error.form.event_start_date = ''
          break
      }
    }

    const validateSize = evChange => {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size > maxSize) {
          toastError('Ukuran file terlalu besar. Maksimal 1 MB.')
          photoUpload.value = ''
          state.photoName = ''
        } else {
          photoUpload.value = file
          const objectURL = URL.createObjectURL(file)
          state.form.poster = objectURL
        }
      }
    }
    const validateExt = evChange => {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        toastError('Hanya dapat upload file dengan ekstensi JPG dan PNG')
        state.photoName = ''
      } else {
        state.photoName = evChange.target.files[0].name
        validateSize(evChange)
      }
    }

    const handleInput = input => {
      const allowedChars = /[0-9\b]/

      if (allowedChars.test(input.key)) {
        const inputValue = input.target.value.replace(/[^\d]/g, '') // Hapus karakter selain digit
        state.form.event_price = inputValue
      } else {
        input.preventDefault()
      }
    }

    const formatPrice = () => {
      const inputValue = state.form.event_price
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      state.form.event_price = formattedValue
    }

    watchEffect(() => {
      checkDataChanged()
      isFormValid()
      isEmpty()
    })

    onMounted(() => {
      getListData()
    })
    computed(() => {
      event.submit = false
    })

    return {
      route,
      picker,
      event,
      router,
      state,
      getListData,
      handleConfirmSubmit,
      handleSubmit,
      handleBack,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      error,
      validateSize,
      validateExt,
      photoUpload,
      handleInput,
      formatPrice,
      formatPriceGet,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
